<template>
  <Card>
    <template #header>
      <CardHeader :title="$t('title')" :subtitle="$t('subtitle')">
        <template v-slot:buttons>
          <SCButton
            v-if="internetAccessEnabled"
            :loading="internetAccessPending"
            @click="$emit('disableInternetAccess')"
          >
            {{ $t("disable") }}
          </SCButton>
          <div v-else>
            <SCButton
              v-if="forceTLSEnabled"
              :loading="internetAccessPending"
              @click="$emit('enableInternetAccess')"
            >
              {{ $t("enable") }}
            </SCButton>
          </div>
        </template>
      </CardHeader>
    </template>
    <template #body>
      <div class="flex flex-row items-center mt-4">
        <DangerSignGlyph
          v-if="internetAccessEnabled"
          class="-mt-0.5 text-warning"
          size="4"
        />
        <font-awesome-icon
          v-if="!internetAccessEnabled"
          icon="lock"
          class="-mt-1 h-4 text-scale-5"
        />

        <span v-if="internetAccessEnabled" class="ml-2">
          {{ $t("enabled") }}
        </span>
        <span v-if="!internetAccessEnabled" class="ml-2">
          {{ $t("disabled") }}
        </span>
      </div>
      <ProTipAlert v-if="!forceTLSEnabled" icon="title" class="mt-4">
        {{ $t("tip") }}
      </ProTipAlert>
    </template>
  </Card>
</template>

<script>
import { computed, defineComponent } from "vue";

import DangerSignGlyph from "@/components/atoms/glyphs/DangerSignGlyph.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import ProTipAlert from "@/components/organisms/alerts/ProTipAlert.vue";
import { dbFeatureIs } from "@/lib/scalingo/dbapi/database";

export default defineComponent({
  name: "InternetAccessibilityCard",
  components: {
    Card,
    CardHeader,
    ProTipAlert,
    SCButton,
    DangerSignGlyph,
  },
  props: {
    db: Object,
    promiseInfo: Object,
  },
  emits: ["enableInternetAccess", "disableInternetAccess"],

  setup(props) {
    const forceTLSEnabled = computed(() =>
      dbFeatureIs(props.db, "force-ssl", "ACTIVATED"),
    );
    const internetAccessEnabled = computed(() =>
      dbFeatureIs(props.db, "publicly-available", "ACTIVATED"),
    );
    const internetAccessPending = computed(() => {
      return (
        props.promiseInfo.isLoading ||
        dbFeatureIs(props.db, "publicly-available", "PENDING_TO_REMOVE") ||
        dbFeatureIs(props.db, "publicly-available", "PENDING_TO_ACTIVATE")
      );
    });

    return {
      forceTLSEnabled,
      internetAccessPending,
      internetAccessEnabled,
    };
  },
});
</script>

<i18n>
en:
  title: "Internet accessibility"
  subtitle: "By default, your database is only accessible from the Scalingo Network."
  tip: "As a security measure, you must first block non-TLS connections in order to make your database accessible from the Internet."
  enable: "Enable"
  disable: "Disable"
  enabled: "This database is publicly accessible on the internet."
  disabled: "This database is only accessible from the Scalingo Network."
fr:
  title: "Accessibilité à internet"
  subtitle: "Par défaut, votre base de données n'est accessible que depuis le réseau Scalingo"
  tip: "Par mesure de sécurité, vous devez d'abord bloquer les connexions non TLS afin de rendre votre base de données accessible depuis Internet."
  enable: "Activer"
  disable: "Désactiver"
  enabled: "Cette base de données est publiquement accessible sur Internet."
  disabled: "Cette base de données est uniquement accessible depuis le réseau Scalingo."
</i18n>
