<template>
  <ViewComponent
    :backups="backups"
    :dbPlan="dbPlan"
    :manualBackupInfo="manualBackupInfo"
    :backupDownloadInfos="backupDownloadInfos"
    :restoreCtx="restoreCtx"
    :app="app"
    :db="db"
    :addon="addon"
    :updateCtx="updateCtx"
    :callPitr="callPitr"
    :firstPitrHour="firstPitrHour"
    :firstPitrMinute="firstPitrMinute"
    :backupError="backupError"
    :backupRunning="backupRunning"
    @startManualBackup="startManualBackup"
    @downloadBackup="downloadBackup"
    @startBackupRestoration="startBackupRestoration"
    @confirmBackupRestoration="confirmBackupRestoration"
    @cancelBackupRestoration="cancelBackupRestoration"
    @startBackupsConfiguration="startBackupsConfiguration"
    @cancelBackupsConfiguration="cancelBackupsConfiguration"
    @updateBackupsConfiguration="updateBackupsConfiguration"
    @clearBackupError="clearBackupError"
  />
</template>

<script>
import { DateTime } from "luxon";
import {
  computed,
  defineComponent,
  onBeforeMount,
  reactive,
  ref,
  nextTick,
} from "vue";

import ViewComponent from "@/components/views/db/backups/List.vue";
import { closeCurrentModal } from "@/lib/modals";
import { filterItems } from "@/lib/pinia/utils/filter-items";
import { promiseInfo, voidPromiseInfo } from "@/lib/promises/info";
import { i18nT } from "@/lib/utils/i18n";
import { importBackupRestorationMethods } from "@/mixins/backup_restoration";
import { importOperationMethods } from "@/mixins/operation";
import { useCurrentAppStore } from "@/stores/current/app";
import {
  currentDB,
  currentDBClient,
  useCurrentDBStore,
} from "@/stores/current/db";
import { useDbBackupsStore } from "@/stores/db/backups";
import { useDbBannersStore } from "@/stores/db/banners";
import { useDbPlanStore } from "@/stores/db/plan";
import { useToastsStore } from "@/stores/toasts";

export default defineComponent({
  name: "BackupsListContainer",
  components: { ViewComponent },
  setup() {
    const t = i18nT();
    const toastsStore = useToastsStore();

    const dbBackupsStore = useDbBackupsStore();
    const dbPlanStore = useDbPlanStore();
    const currentAppStore = useCurrentAppStore();
    const currentDbStore = useCurrentDBStore();
    const bannersStore = useDbBannersStore();
    const updateCtx = ref(null);
    const backupError = ref(null);

    onBeforeMount(() => dbBackupsStore.ensure());

    const manualBackupInfo = ref(voidPromiseInfo());
    const backupDownloadInfos = reactive({});
    const restoreCtx = ref(null);

    const { firstPitrHour, firstPitrMinute } = extractPitrHourAndMinute();
    const { fetchDbOperationById } = importOperationMethods();
    const { fetchDbBackupRestorationById } = importBackupRestorationMethods();

    function clearBackupError() {
      backupError.value = null;
    }

    function extractPitrHourAndMinute() {
      const dateTime = DateTime.fromISO(
        currentDbStore.database.first_pitr_backup,
      ).toUTC();

      const firstPitrHour = dateTime.hour;
      const firstPitrMinute = Math.min(dateTime.minute + 1, 59); // To count possible seconds in the first PITR time but stay under 60

      return { firstPitrHour, firstPitrMinute };
    }

    async function callPitr(pitrTime) {
      const client = await currentDBClient();
      const db = currentDB();

      try {
        const response = await client.Database.pgPointInTimeRestoration(
          db.id,
          pitrTime,
        );
        fetchDbOperationById(client, {
          id: response.operation_id,
        });
        closeCurrentModal();
        clearBackupError();
      } catch (e) {
        backupError.value = e;
      }
    }

    async function confirmBackupRestoration() {
      const { backup } = restoreCtx.value;
      const client = await currentDBClient();

      client.Backups.restore(backup.database_id, backup.id).then((reponse) => {
        fetchDbBackupRestorationById(client, {
          id: reponse.id,
          database_id: backup.database_id,
        });
        toastsStore.addOne({
          type: "success",
          title: t("restore.success.title"),
          message: t("restore.success.message"),
        });
        closeCurrentModal();
      });
    }

    async function startManualBackup() {
      manualBackupInfo.value = await dbBackupsStore.scheduleManual();

      try {
        await manualBackupInfo.value.promise;
        await nextTick();

        toastsStore.addOne({
          type: "success",
          title: t("manual.success.title"),
          message: t("manual.success.message"),
        });
      } catch (e) {
        toastsStore.addOne({
          type: "error",
          title: t("manual.error.title"),
          message: t("manual.error.message", { err: e.data.error }),
        });
      }
    }

    async function updateBackupsConfiguration(payload) {
      const client = await currentDBClient();
      const db = currentDB();

      if (typeof payload.periodic_backups_enabled === "boolean") {
        db.periodic_backups_enabled = payload.periodic_backups_enabled;
      }

      // check if periodic_backups_scheduled_at is not null or undefined but can be equal to zero
      if (payload.periodic_backups_scheduled_at != null) {
        db.periodic_backups_scheduled_at =
          payload.periodic_backups_scheduled_at;
      }

      try {
        client.Database.updateDatabase(db.id, db)
          .then(() =>
            currentDbStore.partialDBRefresh("periodic_backups_scheduled_at"),
          )
          .then(() => {
            toastsStore.addOne({
              type: "success",
              title: t("scheduling.success.title"),
              message: t("scheduling.success.message"),
            });
          });
        closeCurrentModal();
      } catch (e) {
        toastsStore.addOne({
          type: "error",
          title: t("scheduling.error.title"),
          message: t("scheduling.error.message"),
        });
      }
    }

    function downloadBackup({ backup }) {
      const promise = currentDBClient()
        .then((client) => {
          return client.Backups.archiveUrl(backup.database_id, backup.id);
        })
        .then((archiveUrl) => (window.location.href = archiveUrl));

      backupDownloadInfos[backup.id] = promiseInfo(promise);
    }

    return {
      app: computed(() => currentAppStore.appInfoOrFull),
      addon: computed(() => currentDbStore.addon),
      db: computed(() => currentDbStore.database),
      backups: computed(() => {
        const filteredItems = filterItems(dbBackupsStore.items, {
          sortBy: "_startedOrCreatedAt",
          sortDirection: "desc",
        });

        return {
          items: filteredItems,
          promiseInfo: dbBackupsStore.promiseInfo,
          any: dbBackupsStore.any,
        };
      }),
      dbPlan: computed(() => {
        return {
          item: dbPlanStore.item,
          promiseInfo: dbPlanStore.promiseInfo,
        };
      }),
      startBackupsConfiguration() {
        updateCtx.value = { info: voidPromiseInfo(), errors: null };
      },
      startBackupRestoration({ backup }) {
        restoreCtx.value = {
          info: voidPromiseInfo(),
          backup,
        };
      },
      updateBackupsConfiguration,
      startManualBackup,
      downloadBackup,
      cancelBackupsConfiguration() {
        updateCtx.value = null;
      },
      cancelBackupRestoration() {
        restoreCtx.value = null;
      },
      backupRunning: computed(() => {
        // If there is a running pitrRestore banner (warning), there is a backup restore in progress.
        return bannersStore.genericBanners.some(
          (obj) => obj.name === "pitrRestore" && obj.kind === "warning",
        );
      }),
      firstPitrHour,
      firstPitrMinute,
      dbBackupsStore,
      manualBackupInfo,
      toastsStore,
      restoreCtx,
      backupDownloadInfos,
      updateCtx,
      confirmBackupRestoration,
      callPitr,
      backupError,
      clearBackupError,
    };
  },
});
</script>

<i18n>
en:
  manual:
    success:
      title: "Manual backup"
      message: "The manual backup will be carried out shortly."
    error:
      title: "Backup failure"
      message: "The backup has not been carried out: {err}"
  restore:
    success:
      title: "Database restoration"
      message: "The restoration will be executed shortly."
  scheduling:
    success:
      title: "Backup schedule"
      message: "Configuration has been updated."
    error:
      title: "Backup schedule failed!"
      message: "Configuration has not been updated."
fr:
  manual:
    success:
      title: "Backup manuel"
      message: "Le backup manuel va être effectué sous peu."
    error:
      title: "Échec du backup"
      message: "Le backup n'a pas été effectué : {err}"
  restore:
    success:
      title: "Restauration de la base de données"
      message: "La restauration va être exécutée sous peu."
  scheduling:
    success:
      title: "Backup programmé!"
      message: "La configuration a été mise à jour."
    error:
      title: "Échec de la programmation du backup!"
      message: "La configuration n'a pas été mise à jour."
</i18n>
