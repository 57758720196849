import { setupPolling } from "@/lib/pinia/utils/polling";
import Client from "@/lib/scalingo/dbapi/client";
import { useCurrentDBStore } from "@/stores/current/db";
import { useDbBannersStore } from "@/stores/db/banners";

type response = {
  operation: operation;
};

type operation = {
  created_at: string;
  finished_at: string;
  status: string;
  type: string;
  id: string;
  error: string;
};

export function importOperationMethods() {
  const setOperationBanner = (operation: operation) => {
    const bannersStore = useDbBannersStore();
    const currentDbStore = useCurrentDBStore();
    const dbId = currentDbStore.database?.id;

    switch (operation.status) {
      case "finished":
        bannersStore.setGenericBanner({
          name: "backupRestoration",
          kind: "success",
          dismissible: true,
          dbId: dbId,
        });
        break;
      case "error":
        bannersStore.setGenericBanner({
          name: "backupRestoration",
          kind: "error",
          dbId: dbId,
        });
        break;
      case "running":
        bannersStore.setGenericBanner({
          name: "backupRestoration",
          kind: "warning",
          dbId: dbId,
          running: true,
        });
        break;
    }
  };

  const fetchDbOperationById = async (client: Client, operation: operation) => {
    const clientResponse: response = await client.Database.getDatabaseOperation(
      operation.id,
    );

    if (clientResponse.operation.type === "pitr_restore") {
      setOperationBanner(clientResponse.operation);
      setupOperationPolling(client, clientResponse.operation);
    }
  };

  const setupOperationPolling = (client: Client, operation: operation) => {
    const stopFn = () => {
      return ["finished", "error"].includes(operation.status);
    };

    const refreshFn = async () => {
      fetchDbOperationById(client, operation);
    };

    const teardownFn = () => {
      // Remove all the banners linked to backupRestoration and redo a setOperationBanner to set the finished or error banner
      clearOperationBanner();
      setOperationBanner(operation);
    };

    setupPolling({
      id: operation.id,
      kind: "dbOperation",
      stopFn,
      refreshFn,
      teardownFn,
    });
  };

  const clearOperationBanner = () => {
    const bannersStore = useDbBannersStore();

    bannersStore.removeGenericBannerType("backupRestoration");
  };

  return {
    fetchDbOperationById,
  };
}
