<template>
  <SCTable class="mt-6" layout="fixed" :headers="headers" :items="databases">
    <template #item.name="{ item }">
      <div class="flex flex-grow place-items-center">
        <span class="flex flex-row items-center">
          <div v-if="item.protected" class="mr-2" :title="$t('lock')">
            <UserLockGlyph class="h-4 text-scale-5" />
          </div>
          {{ item.name }}
        </span>
      </div>
    </template>

    <template #item.actions="{ item }">
      <HorizontalDottedDropdown
        :actions="actionsFor(item)"
        class="flex justify-end"
        @action="actionClicked"
      />
    </template>
  </SCTable>
</template>

<script>
import { defineComponent } from "vue";

import UserLockGlyph from "@/components/atoms/glyphs/UserLockGlyph.vue";
import HorizontalDottedDropdown from "@/components/molecules/dropdowns/HorizontalDottedDropdown.vue";
import SCTable from "@/components/organisms/tables/SCTable.vue";
import { i18nT } from "@/lib/utils/i18n";

export default defineComponent({
  name: "DatabasesList",
  components: {
    SCTable,
    UserLockGlyph,
    HorizontalDottedDropdown,
  },
  props: {
    databases: Array,
  },
  emits: ["startDatabaseDeletion", "startDatabaseEmptying"],

  setup(props, { emit }) {
    const t = i18nT();

    const actionsFor = (database) => {
      const actions = [];
      const wrapperClass = "leading-5";

      actions.push({
        wrapperClass,
        text: t("actions.empty"),
        key: "empty",
        target: database,
      });

      if (!database.protected) {
        actions.push({
          wrapperClass,
          text: t("actions.drop"),
          key: "drop",
          kind: "warning",
          target: database,
        });
      }

      return actions;
    };

    const actionClicked = ({ action }) => {
      if (action.key === "drop") {
        emit("startDatabaseDeletion", {
          mysql_database: action.target,
        });
        return;
      } else if (action.key === "empty") {
        emit("startDatabaseEmptying", {
          mysql_database: action.target,
        });
      }
    };

    return {
      actionsFor,
      actionClicked,
      headers: [
        { value: "name", text: t("headers.name"), class: "w-64" },

        { value: "actions", text: "" },
      ],
    };
  },
});
</script>

<i18n>
en:
  headers:
    name: "Name"
  lock: "The default database cannot be deleted. It can only be emptied."
  actions:
    empty: "Empty"
    drop: "Drop"
fr:
  headers:
    name: "Nom"
  lock: "La base de données par défaut ne peut pas être supprimée. Elle peut seulement être vidée."
  actions:
    empty: "Vider"
    drop: "Supprimer"
</i18n>
