export default {
  "notifications": {
    "databases": {
      "backups": {
        "manual": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual backup successful"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The manual backup was successfully created."])}
        }
      },
      "features": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration updated"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The configuration has been successfully updated."])}
      }
    },
    "addons": {
      "provisioned": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addon successfully provisioned"])},
        "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your ", _interpolate(_named("addon")), " addon was successfully provisioned"])}
      }
    },
    "handlers": {
      "billingProfile": {
        "create": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All good!"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The billing profile was created."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not create the billing profile"])}
          }
        },
        "edit": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All good!"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The billing profile has been updated."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not update the billing profile"])}
          }
        }
      },
      "hdsContact": {
        "edit": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All good!"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The point of contact health professional has been updated."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not update the contact"])}
          }
        },
        "create": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All good!"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your contact was created"])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not create the contact"])}
          }
        },
        "touch": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All good!"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The point of contact health professional has been updated."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not update the contact"])}
          }
        }
      },
      "account": {
        "deletion": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account successfully deleted!"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your deletion request has been accepted and is being processed."])}
          }
        },
        "edit": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All good!"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account was updated"])}
          }
        },
        "emailChanged": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You got mail!"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A confirmation email has been sent to ", _interpolate(_named("email")), ". Click on the confirmation link to validate this email address."])}
          }
        },
        "passwordChanged": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password changed"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password has been changed"])}
          }
        }
      },
      "autoscaler": {
        "create": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoscaler enabled!"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Autoscaling is enabled for container ", _interpolate(_named("container")), ", varying from ", _interpolate(_named("min")), " to ", _interpolate(_named("max")), "."])}
          }
        },
        "edit": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoscaler updated!"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Autoscaler for container ", _interpolate(_named("container")), " has been updated, varying from ", _interpolate(_named("min")), " to ", _interpolate(_named("max")), "."])}
          }
        },
        "delete": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoscaler removed!"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The autoscaler for container ", _interpolate(_named("container")), " has been removed."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Could not remove the autoscaler for container ", _interpolate(_named("container")), "."])}
          }
        },
        "disable": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoscaler disabled!"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The autoscaler for container ", _interpolate(_named("container")), " has been disabled."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Could not disable the autoscaler for container ", _interpolate(_named("container")), "."])}
          }
        },
        "enable": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoscaler enabled!"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The autoscaler for container ", _interpolate(_named("container")), " has been enabled."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Could not enable the autoscaler for container ", _interpolate(_named("container")), "."])}
          }
        }
      },
      "alerts": {
        "create": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alert created!"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alert was successfully created."])}
          }
        },
        "delete": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alert removed!"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The alert was successfully removed."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The alert could not be removed."])}
          }
        },
        "edit": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alert updated!"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alert was successfully updated."])}
          }
        }
      },
      "containers": {
        "restart": {
          "generic": {
            "error": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Containers could not be restarted."])}
            }
          },
          "restarting": {
            "error": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Containers are currently restarting."])}
            }
          }
        },
        "scale": {
          "generic": {
            "error": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Containers could not be scaled."])}
            }
          },
          "free-trial": {
            "error": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure"])},
              "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Owner ", _interpolate(_named("owner")), " must end the free trial in order to do this action."])}
            }
          },
          "scaling": {
            "error": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Containers are currently scaling."])}
            }
          }
        }
      },
      "data-access-consent": {
        "create": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consent accepted!"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data access consent was successfully accepted."])}
          }
        },
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The consent could not be accepted."])}
        }
      },
      "contracts": {
        "accept": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract accepted!"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract was successfully accepted."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The contract could not be accepted."])}
          }
        }
      },
      "domains": {
        "setDefault": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain made canonical!"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain was made canonical."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not make the domain canonical."])}
          }
        },
        "unsetDefault": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain is now non canonical!"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain was made non canonical."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not make the domain non canonical."])}
          }
        },
        "delete": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain removed!"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain was removed"])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not remove domain."])}
          }
        },
        "edit": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain certificate updated!"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your certificate and key were successfully added."])}
          }
        }
      },
      "collaborationLink": {
        "copy": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copied!"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The invitation link has been copied to the clipboard!"])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not copy to the clipboard"])}
          }
        }
      },
      "gitRemote": {
        "copy": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copied!"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The git remote URL has been copied to the clipboard!"])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not copy to the clipboard"])}
          }
        }
      },
      "collaborator": {
        "create": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaborator invited!"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaborator has been invited."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not invite the collaborator."])}
          }
        },
        "delete": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaborator removed!"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaborator was removed"])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not remove the collaborator. Are they linking the app to the SCM?"])}
          }
        }
      },
      "app": {
        "delete": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App was deleted!"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The app ", _interpolate(_named("name")), " was successfully deleted."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App could not be deleted!"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The app ", _interpolate(_named("name")), " was not deleted."])}
          }
        },
        "rename": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App was renamed!"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The app was successfully renamed to ", _interpolate(_named("name")), "."])}
          }
        },
        "transfer": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App was transfered!"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The app ", _interpolate(_named("app")), " was successfully transfered to ", _interpolate(_named("owner")), "."])}
          }
        }
      },
      "appSettings": {
        "edit": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application updated!"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The routing settings were successfully updated."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The routing settings could not be updated."])}
          }
        }
      },
      "appStackSettings": {
        "edit": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application updated!"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The stack settings were successfully updated."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The stack settings could not be updated."])}
          }
        }
      },
      "deployments": {
        "manual": {
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not deploy"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The manual deployment failed."])}
          }
        }
      },
      "deploymentCache": {
        "delete": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cache removed"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The deployment cache was successfully removed."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The deployment cache could not be removed."])}
          }
        }
      },
      "notifiers": {
        "create": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifier created!"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["'", _interpolate(_named("name")), "' was successfully created."])}
          }
        },
        "delete": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifier removed!"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["'", _interpolate(_named("name")), "' was successfully removed."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["'", _interpolate(_named("name")), "' could not be removed."])}
          }
        },
        "disable": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifier disabled!"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["'", _interpolate(_named("name")), "' was disabled."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["'", _interpolate(_named("name")), "' could not be disabled."])}
          }
        },
        "edit": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifier updated!"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["'", _interpolate(_named("name")), "' was successfully updated."])}
          }
        },
        "enable": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifier enabled!"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["'", _interpolate(_named("name")), "' was enabled."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["'", _interpolate(_named("name")), "' could not be enabled."])}
          }
        }
      },
      "paymentMethod": {
        "card": {
          "create": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card saved!"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your card was successfully registered."])}
            }
          },
          "delete": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card deleted!"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your card was successfully removed."])}
            },
            "error": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure"])},
              "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your card could not be removed. ", _interpolate(_named("message"))])}
            }
          },
          "setDefault": {
            "error": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your card could not be set as default."])}
            }
          }
        },
        "sepa": {
          "create": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEPA account saved!"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your SEPA account was successfully registered."])}
            }
          },
          "delete": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEPA account deleted!"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your SEPA account was successfully removed."])}
            },
            "error": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your SEPA account could not be removed."])}
            }
          },
          "setDefault": {
            "error": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your SEPA account could not be set as default."])}
            }
          }
        }
      },
      "paypal": {
        "initiate": {
          "genericError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paypal could not be reached."])}
        },
        "validate": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paypal transaction successful!"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account balance has been updated accordingly."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paypal transaction failure"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong with the transaction."])}
          }
        }
      },
      "reviewApps": {
        "manual": {
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not deploy this review app"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The deployment of this review app did not work."])}
          }
        },
        "free-trial": {
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot deploy review apps"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Owner (", _interpolate(_named("owner")), ") must end the free trial to deploy review apps."])}
          }
        }
      },
      "scmIntegrations": {
        "link": {
          "github": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connected to GitHub"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connected to your github.com account!"])}
            },
            "error": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection error"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible to connect to your github.com account"])}
            },
            "already_linked": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounts already linked"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Github account is already link to another Scalingo account"])}
            },
            "linked_to_another_account": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linked to another account"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your github.com account is linked to another Scalingo account"])}
            }
          },
          "gitlab": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connected to GitLab"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connected to your gitlab.com account!"])}
            },
            "error": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection error"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible to connect to your gitlab.com account"])}
            },
            "already_linked": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounts already linked"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Gitlab account is already link to another Scalingo account"])}
            },
            "linked_to_another_account": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link to another account"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your gitlab.com account is linked to another Scalingo account"])}
            }
          }
        },
        "create": {
          "github": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connected to GitHub"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful connection to your GitHub Enterprise account!"])}
            }
          },
          "gitlab": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connected to GitLab"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful connection to your GitLab self-hosted account!"])}
            }
          }
        },
        "delete": {
          "github": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnected from GitHub"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful disconnection from your GitHub account"])}
            }
          },
          "gitlab": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnected from Gitlab"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful disconnection from your GitLab account"])}
            }
          },
          "github-enterprise": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnected from GitHub"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful disconnection from your GitHub Enterprise account"])}
            }
          },
          "gitlab-self-hosted": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnected from Gitlab"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful disconnection from your GitLab self-hosted account"])}
            }
          }
        }
      },
      "scmRepoLink": {
        "delete": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repository unlinked!"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The SCM repository was unlinked from this app"])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure!"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The SCM repository could not be unlinked from this app"])}
          }
        },
        "edit": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repository linked!"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You're set as the new linker"])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure!"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("error"))])}
          }
        }
      },
      "tokens": {
        "create": {
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure!"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The token '", _interpolate(_named("name")), "' could not be create."])}
          }
        },
        "delete": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token removed"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The token '", _interpolate(_named("name")), "' was removed."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure!"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The token '", _interpolate(_named("name")), "' could not be removed."])}
          }
        }
      },
      "sshKeys": {
        "create": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSH key created"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The SSH key was created."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure!"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The SSH key could not be created."])}
          }
        },
        "delete": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSh key removed"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The SSH key '", _interpolate(_named("name")), "' was removed."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure!"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The SSH key '", _interpolate(_named("name")), "' could not be removed."])}
          }
        }
      },
      "user": {
        "stopFreeTrial": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All good!"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You're out of the free trial period."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible to break out of the free trial."])}
          }
        }
      },
      "userPreferences": {
        "edit": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All good!"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your preferences have been updated!"])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your preferences could not be updated."])}
          }
        }
      },
      "variable": {
        "copy": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copied!"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The variable and value have been copied to the clipboard"])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not copy to the clipboard"])}
          }
        },
        "create": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variable created!"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The variable '", _interpolate(_named("name")), "' was successfully created."])}
          }
        },
        "delete": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Removed!"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The variable '", _interpolate(_named("name")), "' was successfully removed."])}
          }
        },
        "edit": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variable updated!"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The variable '", _interpolate(_named("name")), "' was successfully updated."])}
          }
        },
        "bulkEdit": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variables updated!"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The environment variables have been successfully updated."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong during the variables updated."])}
          }
        }
      }
    }
  }
}