<template>
  <SCBanner
    :dismissible="banner.dismissible || false"
    :kind="banner.kind"
    @dismiss="banner.show = false"
  >
    <div>
      <div class="flex row">
        <ReloadGlyph v-if="banner.running" class="mr-2" :animated="true" />
        <WarningTriangle v-else-if="banner.kind === 'warning'" class="mr-2" />
        <DangerSignGlyph v-else-if="banner.kind === 'error'" class="mr-2" />
        <CheckGlyph v-else-if="banner.kind === 'success'" class="mr-2" />
        <InformationGlyph v-else-if="banner.kind === 'info'" class="mr-2" />
        {{ computedTitle }}
      </div>
      <p class="ml-1 mt-2 font-normal">
        {{ computedSubtitle }}
      </p>
    </div>
  </SCBanner>
</template>

<script>
import { defineComponent } from "vue";

import CheckGlyph from "@/components/atoms/glyphs/CheckGlyph.vue";
import DangerSignGlyph from "@/components/atoms/glyphs/DangerSignGlyph.vue";
import InformationGlyph from "@/components/atoms/glyphs/InformationGlyph.vue";
import ReloadGlyph from "@/components/atoms/glyphs/ReloadGlyph.vue";
import WarningTriangle from "@/components/graphics/icons/WarningTriangle.vue";
import SCBanner from "@/components/molecules/banners/SCBanner.vue";

export default defineComponent({
  name: "SuccessBanner",
  components: {
    SCBanner,
    DangerSignGlyph,
    WarningTriangle,
    CheckGlyph,
    InformationGlyph,
    ReloadGlyph,
  },
  props: {
    banner: Object,
    title: String,
    subtitle: String,
  },
  computed: {
    computedTitle: function () {
      return (
        this.banner.title ??
        this.$t(`${this.banner.name}.${this.banner.kind}.title`)
      );
    },
    computedSubtitle: function () {
      return (
        this.banner.subtitle ??
        this.$t(`${this.banner.name}.${this.banner.kind}.subtitle`)
      );
    },
  },
});
</script>

<i18n>
  en:
    backupRestoration:
      success:
        title: "The database has been successfully restored"
        subtitle: "You can access your data safely."
      warning:
        title: "Database restoration in progress"
        subtitle: "Depending on the size of your database, this process may take anywhere from a few minutes to several hours. We recommend that you avoid any operations until the restoration is complete."
      error:
        title: "An error occurred during the database restoration."
        subtitle: "We encountered an issue while restoring the database. To ensure its integrity and current status, please reach out to our support team."
      info:
        title: "Database restoration scheduled."
        subtitle: "The restoration process will start soon. We recommend that you avoid any operations until the restoration is complete."
    maintenanceInProgress:
      warning:
        title: "Maintenance in progress."
        subtitle: "Your database will remain available, but please avoid making any changes until the operation has been completed."
  fr:
    backupRestoration:
      success:
        title: "La base de données a été restaurée avec succès."
        subtitle: "Vous pouvez accéder à vos données en toute sécurité."
      warning:
        title: "Restauration de la base de données en cours."
        subtitle: "Selon la taille de votre base de données, cette opération peut prendre de quelques minutes à plusieurs heures. Nous vous recommandons d’éviter toute opération jusqu’à ce que la restauration soit terminée."
      error:
        title: "Une erreur est survenue lors de la restauration de la base de données."
        subtitle: "Nous avons rencontré un problème lors de la restauration de la base de données. Pour vérifier son intégrité et son état actuel, veuillez contacter notre équipe de support."
      info:
        title: "Restauration planifiée."
        subtitle: "Le processus de restauration va bientôt commencer. Nous vous recommandons d’éviter toute opération jusqu’à ce que la restauration soit terminée."
    maintenanceInProgress:
      warning:
        title: "Maintenance en cours."
        subtitle: "Votre base de données reste disponible, mais il est conseillé d’éviter toute modification tant que l’opération n’est pas terminée."

</i18n>
