export default {
  "generic": {
    "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
    "resetForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser le formulaire"])},
    "by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["par"])},
    "on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sur"])},
    "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vous"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tous"])},
    "allFemale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["toutes"])}
  },
  "titles": {
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vue générale"])},
    "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apps"])},
    "consumption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consommation"])},
    "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introuvable"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A propos"])},
    "account": {
      "root": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres"])},
      "authentication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentification"])},
      "keys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clés SSH"])},
      "tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokens"])},
      "integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intégrations SCM"])},
      "deletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppression"])},
      "hds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hébergement de données de santé (HDS)"])},
      "referrals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parrainage"])}
    },
    "billing": {
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil de facturation"])},
      "vouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codes promo"])},
      "credits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crédits"])},
      "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crédit"])},
      "paymentMethods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méthodes de paiement"])},
      "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factures"])},
      "invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facture"])},
      "operations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opérations détaillées"])},
      "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commandes"])},
      "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande"])}
    },
    "app": {
      "root": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name"))])},
      "creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle app"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vue générale"])},
      "resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ressources"])},
      "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Métriques"])},
      "environment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Environnement"])},
      "collaborators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaborateurs"])},
      "domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noms de domaines"])},
      "alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alertes"])},
      "routing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Routing"])},
      "managements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion"])},
      "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introuvable"])},
      "addons": {
        "creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvel addon"])},
        "edition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le plan de l'addon"])}
      },
      "deploy": {
        "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déploiements"])},
        "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déploiement"])},
        "manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déploiement manuel"])},
        "config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration du déploiement"])}
      },
      "reviewApps": {
        "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review apps"])},
        "manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création manuelle d'une review app"])},
        "config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration des review apps"])}
      },
      "activity": {
        "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité"])},
        "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evènement"])}
      },
      "logs": {
        "live": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logs"])},
        "archives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archives des logs"])}
      }
    },
    "db": {
      "root": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name"))])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vue générale"])},
      "logs": {
        "live": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logs"])},
        "archives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archives des logs"])}
      },
      "runningQueries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requêtes en cours"])},
      "queryStatistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistiques des requêtes"])},
      "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Métriques"])},
      "backups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backups"])},
      "settings": {
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Général"])},
        "maintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintenance"])},
        "networking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès Internet"])},
        "configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration"])},
        "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs"])},
        "databases-management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des bases de données"])}
      }
    }
  }
}