<template>
  <FormModal
    :withCancelBtn="false"
    :promiseInfo="context.info"
    :title="$t('title')"
    :fieldErrors="errors"
    panelClasses="sm:w-full sm:max-w-lg"
    :submitLabel="$t('action')"
    @close="(e) => $emit('close', e)"
    @submit="onSubmit"
  >
    <div class="mt-6">
      <Field
        v-slot="{ field, errors, handleChange }"
        v-model="form.name"
        name="name"
        rules="required|min:1|max:64|mysqlDatabaseName"
      >
        <span
          class="text-sm font-medium leading-5 h-6 text-scale-10 capitalize"
        >
          {{ $t("name") }}
        </span>

        <TextInput
          :modelValue="field.value"
          :name="field.name"
          :errors="errors"
          :label="$t('name')"
          @update:modelValue="handleChange"
        />

        <div class="mt-4 text-scale-10">
          {{ $t("dbNameValidation.title") }}
          <ul class="list-inside list-disc">
            <li>{{ $t("dbNameValidation.uniqueness") }}</li>
            <li>{{ $t("dbNameValidation.hasLetter") }}</li>
            <li>{{ $t("dbNameValidation.length") }}</li>
            <li>{{ $t("dbNameValidation.allowedCharacters") }}</li>
          </ul>
        </div>
      </Field>
    </div>

    <SCAlert v-if="context.info?.isError" class="my-4" kind="error">
      <p class="px-4 font-bold">{{ context.error }}</p>
    </SCAlert>
  </FormModal>
</template>

<script>
import { Field } from "vee-validate";
import { computed, defineComponent, reactive } from "vue";

import TextInput from "@/components/atoms/inputs/TextInput.vue";
import SCAlert from "@/components/molecules/alerts/SCAlert.vue";
import FormModal from "@/components/molecules/modals/FormModal.vue";
import { FormModalHandlerMixin } from "@/mixins/form_handler_mixin";
import ModelsTranslation from "@/mixins/models_translation";

export function initForm(extra) {
  return {
    ...extra,
  };
}

export default defineComponent({
  components: {
    Field,
    FormModal,
    TextInput,
    SCAlert,
  },
  mixins: [ModelsTranslation, FormModalHandlerMixin],
  props: {
    context: { type: Object, required: true },
  },
  emits: ["close", "submit"],
  setup(props, { emit }) {
    const form = reactive(initForm());

    const formattedErrors = computed(() => {
      if (!props.context?.info?.err) return null;

      return props.context.info.err.data.db;
    });

    const onSubmit = () => {
      emit("submit", form);
    };

    const errors = computed(() => props.context.errors);

    return {
      form,
      errors,
      formattedErrors,
      onSubmit,
    };
  },
});
</script>

<i18n>
en:
  title: "Create a new database"
  dbNameValidation:
    title: "The database name must:"
    uniqueness: "Be unique"
    hasLetter: "Contain at least one letter"
    length: "Be between 1 and 64 characters"
    allowedCharacters: "Contain only alphanumeric characters, underscores, or dollar signs"
  name: "Name"
  action: "Create this database"
fr:
  title: "Créer une nouvelle base de données"
  dbNameValidation:
    title: "Le nom de la base de données doit :"
    uniqueness: "Être unique"
    hasLetter: "Contenir entre 1 et 64 caractères"
    length: "Contenir au moins une lettre"
    allowedCharacters: "Contenir uniquement des caractères alphanumériques, tiret bas ou symbole dollar"
  name: "Nom"
  action: "Créer cette base de donnée"
</i18n>
