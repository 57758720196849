<template>
  <div ref="bannerContainerRef" class="absolute w-screen left-0 z-40">
    <MaintenanceScheduledBanner
      v-if="showMaintenanceScheduledBanner"
      :items="notifiedItems"
      :dbMaintenance="dbMaintenance"
      :db="db"
    />
    <GenericBanner
      v-for="(banner, i) in activeBanners"
      :key="i"
      :banner="banner"
    />
  </div>
  <div
    :style="{ height: bannerContainerHeight + 'px' }"
    class="relative z-0"
  ></div>
</template>

<script>
import { computed, defineComponent, ref, onMounted, onUnmounted } from "vue";

import GenericBanner from "@/components/organisms/banners/GenericBanner.vue";
import MaintenanceScheduledBanner from "@/components/organisms/banners/MaintenanceScheduledBanner.vue";
import { useDbBannersStore } from "@/stores/db/banners";

export default defineComponent({
  name: "DbBannersManager",
  components: {
    MaintenanceScheduledBanner,
    GenericBanner,
  },
  props: {
    db: Object,
    dbMaintenance: Object,
    notifiedItems: Array,
  },
  setup(props) {
    const bannerContainerRef = ref(null);
    const bannerContainerHeight = ref(0);
    const showMaintenanceScheduledBanner = computed(
      () => props.notifiedItems?.length > 0,
    );
    const updateHeight = () => {
      if (bannerContainerRef.value) {
        bannerContainerHeight.value = bannerContainerRef.value.offsetHeight;
      }
    };
    const bannersStore = useDbBannersStore();
    const activeBanners = computed(() => {
      return bannersStore.genericBanners.filter(
        (banner) => banner.dbId === props.db.id && banner.show,
      );
    });
    let observer;

    onMounted(() => {
      updateHeight();
      observer = new ResizeObserver(updateHeight);
      observer.observe(bannerContainerRef.value);
    });

    onUnmounted(() => {
      if (observer && bannerContainerRef.value) {
        observer.unobserve(bannerContainerRef.value);
      }
    });

    return {
      showMaintenanceScheduledBanner,
      bannerContainerRef,
      bannerContainerHeight,
      activeBanners,
    };
  },
});
</script>
