import { setupPolling } from "@/lib/pinia/utils/polling";
import { BackupRestoration } from "@/lib/scalingo/dbapi/backup_restorations";
import Client from "@/lib/scalingo/dbapi/client";
import { useCurrentDBStore } from "@/stores/current/db";
import { useDbBannersStore } from "@/stores/db/banners";

export function importBackupRestorationMethods() {
  const setupBackupRestorationPolling = (
    client: Client,
    backupRestoration: BackupRestoration,
  ) => {
    const stopFn = () => {
      return ["done", "error"].includes(backupRestoration.status);
    };

    const refreshFn = async () => {
      fetchDbBackupRestorationById(client, backupRestoration);
    };

    const teardownFn = () => {
      clearbackupRestorationBanner();
      setBackupRestorationBanner(backupRestoration);
    };

    setupPolling({
      id: backupRestoration.id,
      kind: "backup",
      stopFn,
      refreshFn,
      teardownFn,
    });
  };

  const fetchDbBackupRestorationById = async (
    client: Client,
    backupRestoration: BackupRestoration,
  ) => {
    const clientResponse: BackupRestoration =
      await client.BackupRestorations.find(
        backupRestoration.database_id,
        backupRestoration.id,
      );

    setBackupRestorationBanner(clientResponse);
    setupBackupRestorationPolling(client, clientResponse);
  };

  const clearbackupRestorationBanner = () => {
    const bannersStore = useDbBannersStore();

    bannersStore.removeGenericBannerType("backupRestoration");
  };

  const setBackupRestorationBanner = (backupRestoration: BackupRestoration) => {
    const currentDbStore = useCurrentDBStore();
    if (!currentDbStore.database) {
      return;
    }

    const bannersStore = useDbBannersStore();

    const dbId = currentDbStore.database.id;

    switch (backupRestoration.status) {
      case "done":
        bannersStore.setGenericBanner({
          name: "backupRestoration",
          kind: "success",
          dismissible: true,
          dbId: dbId,
        });
        break;
      case "error":
        bannersStore.setGenericBanner({
          name: "backupRestoration",
          kind: "error",
          dbId: dbId,
        });
        break;
      case "running":
        bannersStore.setGenericBanner({
          name: "backupRestoration",
          kind: "warning",
          dbId: dbId,
          running: true,
        });
        break;
      case "scheduled":
        bannersStore.setGenericBanner({
          name: "backupRestoration",
          kind: "warning",
          dbId: dbId,
        });
        break;
    }
  };

  return {
    fetchDbBackupRestorationById,
  };
}
