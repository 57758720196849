export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "backupRestoration": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The database has been successfully restored"])},
            "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can access your data safely."])}
          },
          "warning": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Database restoration in progress"])},
            "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depending on the size of your database, this process may take anywhere from a few minutes to several hours. We recommend that you avoid any operations until the restoration is complete."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred during the database restoration."])},
            "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We encountered an issue while restoring the database. To ensure its integrity and current status, please reach out to our support team."])}
          },
          "info": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Database restoration scheduled."])},
            "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The restoration process will start soon. We recommend that you avoid any operations until the restoration is complete."])}
          }
        },
        "maintenanceInProgress": {
          "warning": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintenance in progress."])},
            "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your database will remain available, but please avoid making any changes until the operation has been completed."])}
          }
        }
      },
      "fr": {
        "backupRestoration": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La base de données a été restaurée avec succès."])},
            "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez accéder à vos données en toute sécurité."])}
          },
          "warning": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restauration de la base de données en cours."])},
            "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selon la taille de votre base de données, cette opération peut prendre de quelques minutes à plusieurs heures. Nous vous recommandons d’éviter toute opération jusqu’à ce que la restauration soit terminée."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue lors de la restauration de la base de données."])},
            "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons rencontré un problème lors de la restauration de la base de données. Pour vérifier son intégrité et son état actuel, veuillez contacter notre équipe de support."])}
          },
          "info": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restauration planifiée."])},
            "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le processus de restauration va bientôt commencer. Nous vous recommandons d’éviter toute opération jusqu’à ce que la restauration soit terminée."])}
          }
        },
        "maintenanceInProgress": {
          "warning": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintenance en cours."])},
            "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre base de données reste disponible, mais il est conseillé d’éviter toute modification tant que l’opération n’est pas terminée."])}
          }
        }
      }
    }
  })
}
