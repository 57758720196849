<template>
  <ConfirmModal
    :title="$t('title')"
    panelClasses="sm:w-full sm:max-w-xl"
    :weakConfirmation="true"
    :canConfirm="allFilled"
    @confirm="handleConfirm"
  >
    <p class="-mt-4 font-normal text-scale-6">
      {{ $t("subtitle") }}
    </p>
    <div class="text-scale-8 flex flex-row space-x-4">
      <span class="items-center mt-4">
        <span class="mr-2 text-sm text-scale-6">{{ $t("selectDay") }}</span>
        <SelectInput v-model="days" :errors="errors" :options="daysOptions" />
      </span>
      <div class="items-center mt-4">
        <span class="mr-2 text-sm text-scale-6">{{ $t("selectHours") }}</span>
        <div class="flex items-center">
          <SelectInput
            v-model="hours"
            :errors="errors"
            class="w-32"
            :options="hoursOptions"
          />
        </div>
      </div>
      <div class="items-center mt-4">
        <span class="mr-2 text-sm text-scale-6">{{ $t("selectMinutes") }}</span>
        <div class="flex items-center">
          <SelectInput
            v-model="minutes"
            :errors="errors"
            class="w-32"
            :options="minutesOptions"
          />
          <span class="ml-2 mt-1">UTC</span>
        </div>
      </div>
    </div>

    <template #weakConfirmationPrompt>
      <span class="font-medium">{{ $t("disclaimer.checkbox") }}</span>
    </template>

    <InformationAlert v-if="backupError" kind="error" class="mt-3">
      {{ backupError }}
    </InformationAlert>

    <InformationAlert kind="warning" class="mt-3">
      {{ $t("warning") }}
    </InformationAlert>

    <p class="mt-2"></p>
  </ConfirmModal>
</template>

<script>
import { DateTime } from "luxon";
import { defineComponent } from "vue";

import SelectInput from "@/components/atoms/inputs/SelectInput.vue";
import ConfirmModal from "@/components/molecules/modals/ConfirmModal.vue";
import InformationAlert from "@/components/organisms/sc_alerts/InformationAlert.vue";

export default defineComponent({
  components: { ConfirmModal, InformationAlert, SelectInput },
  props: {
    firstPitrBackup: Object,
    callPitr: Function,
    firstPitrHour: Number,
    firstPitrMinute: Number,
    backupError: String,
  },
  emits: ["confirm"],
  data() {
    return {
      days: null,
      hours: null,
      minutes: null,
    };
  },
  computed: {
    isSelectedIsFirstPirt() {
      const inputDate = DateTime.fromISO(this.days);
      const firstPitr = DateTime.fromISO(this.firstPitrBackup);

      return inputDate.hasSame(firstPitr, "day");
    },
    isSelectedDateToday() {
      const inputDate = DateTime.fromISO(this.days);
      const today = DateTime.now();

      return inputDate.hasSame(today, "day");
    },
    isSelectedLastHour() {
      return DateTime.now().toFormat("HH") === this.hours;
    },
    allFilled() {
      return this.days !== null && this.hours !== null && this.minutes !== null;
    },
    daysOptions() {
      const daysOptions = [];

      const pitrDate = DateTime.fromISO(this.firstPitrBackup);
      const sevenDaysAgo = DateTime.now().minus({ days: 6 });
      const now = DateTime.now();
      const startDate = pitrDate > sevenDaysAgo ? pitrDate : sevenDaysAgo;

      const daysBetween = Math.floor(now.diff(startDate, "days").days);

      for (let i = 0; i <= daysBetween; i++) {
        let date = startDate.plus({ days: i }).toFormat("yyyy-MM-dd");
        daysOptions.push({ text: date, value: date });
      }

      return daysOptions;
    },
    minutesOptions() {
      let minutesOptions = [];
      const startMinute = this.isSelectedIsFirstPirt ? this.firstPitrMinute : 0;
      const endMinute =
        this.isSelectedDateToday && this.isSelectedLastHour
          ? DateTime.now().minus({ minutes: 5 }).toFormat("mm")
          : 59;

      for (let i = startMinute; i <= endMinute; i++) {
        minutesOptions.push({ text: i, value: i.toString().padStart(2, "0") });
      }
      return minutesOptions;
    },
    hoursOptions() {
      const hoursOptions = [];
      const locale = this.$i18n.locale;
      const hour12 = locale === "en";
      const startHour = this.isSelectedIsFirstPirt ? this.firstPitrHour : 0;
      const endHour = this.isSelectedDateToday
        ? DateTime.now().minus({ minutes: 5 }).toFormat("HH")
        : 23;

      for (let i = startHour; i <= endHour; i++) {
        const time = DateTime.fromObject({ hour: i }).toLocaleString({
          hour: "numeric",
          hour12: hour12,
          locale: locale,
        });
        hoursOptions.push({ text: time, value: i.toString().padStart(2, "0") });
      }

      return hoursOptions;
    },
    pitrTime() {
      return DateTime.fromISO(
        `${this.days}T${this.hours}:${this.minutes}:00.0`,
        { zone: "utc" },
      ).toISO();
    },
  },
  methods: {
    handleConfirm() {
      this.callPitr(this.pitrTime);
    },
  },
});
</script>

<i18n>
en:
  title: "Point-In-Time Recovery"
  subtitle: "Select a restore point for this database."
  disclaimer:
    checkbox: "I understand that this action will permanently delete existing data and cannot be cancelled or undone once started."
  warning: "Once the restoration is complete, the continuous backup function will be reset. Please contact our support team for any specific needs."
  selectDay: "Select a date"
  selectHours: "Hour"
  selectMinutes: "Minute"
fr:
  title: "Point-In-Time Recovery"
  subtitle: "Sélectionnez un point de restauration pour cette base de données."
  disclaimer:
    checkbox: "Je comprends que cette action effacera définitivement les données existantes et qu'elle ne pourra pas être annulée."
  warning: "Une fois la restauration terminée, la fonction de sauvegarde continue sera réinitialisée. Contactez notre équipe support en cas de besoin spécifique."
  selectDay: "Sélectionnez une date"
  selectHours: "Heure"
  selectMinutes: "Minute"
</i18n>
