import { isEqual } from "lodash";
import { defineStore } from "pinia";
import { ref, Ref } from "vue";

interface Banner {
  show?: boolean;
  name?: string;
  kind: string;
  dbId?: string;
  dismissible?: boolean;
  running?: boolean;
  title?: string;
  subtitle?: string;
}

export const useDbBannersStore = defineStore("dbBanners", () => {
  const genericBanners: Ref<Banner[]> = ref([]);

  const setGenericBanner = function (newBanner: Banner) {
    newBanner.show ??= true; // set show to true if not set
    const filteredBanners = genericBanners.value.filter(
      (f) => !isEqual(f, newBanner),
    );

    genericBanners.value = [...filteredBanners, newBanner];
  };

  const removeGenericBanner = function (bannerType: Banner) {
    genericBanners.value = genericBanners.value.filter(
      (f) => !isEqual(f, bannerType),
    );
  };

  const removeGenericBannerType = function (name: string) {
    genericBanners.value = genericBanners.value.filter((f) => f.name !== name);
  };

  return {
    genericBanners,
    setGenericBanner,
    removeGenericBanner,
    removeGenericBannerType,
  };
});
