export function displaySwapStatus(
  dbName: string,
  swapLimit: number,
  consumedValue: number,
): string | null {
  let warningLimit = null;
  let koLimit = null;

  switch (dbName) {
    case "redis":
      warningLimit = swapLimit * 0.1;
      koLimit = swapLimit * 0.5;
      break;
    case "postgresql":
      warningLimit = swapLimit * 0.1;
      koLimit = swapLimit * 0.5;
      break;
    case "mysql":
      warningLimit = swapLimit * 0.1;
      koLimit = swapLimit * 0.5;
      break;
  }
  if (koLimit && warningLimit) {
    if (consumedValue > koLimit) {
      return "ko";
    }
    if (consumedValue > warningLimit) {
      return "warning";
    }
    return "ok";
  }
  return null;
}

export function displayCPUStatus(
  dbName: string,
  consumedValue: number,
): string | null {
  let warningLimit = null;
  let koLimit = null;

  switch (dbName) {
    case "redis":
      warningLimit = 80;
      koLimit = 100;
      break;
    case "postgresql":
      break;
    case "mysql":
      break;
  }
  if (warningLimit && koLimit) {
    if (consumedValue > koLimit) {
      return "ko";
    }
    if (consumedValue > warningLimit) {
      return "warning";
    }
    return "ok";
  } else {
    return null;
  }
}

export function displayDiskStatus(
  dbName: string,
  disk: number,
  consumedValue: number,
): string | null {
  let warningLimit = null;

  switch (dbName) {
    case "redis":
      warningLimit = disk * 1024 * 1024;
      break;
    case "postgresql":
      warningLimit = disk * 1024 * 1024;
      break;
    case "mysql":
      warningLimit = disk * 1024 * 1024;
      break;
  }

  if (warningLimit) {
    if (consumedValue > warningLimit) {
      return "warning";
    }
    return "ok";
  }
  return null;
}

export function displayMemoryStatus(
  dbName: string,
  memoryLimit: number,
  consumedValue: number,
): string | null {
  let warningLimit = null;
  let koLimit = null;

  if (dbName == "redis") {
    warningLimit = memoryLimit * 0.8;
    koLimit = memoryLimit * 0.9;
    if (consumedValue > koLimit) {
      return "ko";
    }
    if (consumedValue > warningLimit) {
      return "warning";
    }
    return "ok";
  } else {
    return null;
  }
}

export function displayConnectionsStatus(
  dbName: string,
  maxConnections: number,
  consumedValue: number,
): string | null {
  let warningLimit = null;
  let koLimit = null;

  switch (dbName) {
    case "redis":
      break;
    case "postgresql":
      warningLimit = maxConnections * 0.6;
      koLimit = maxConnections * 0.9;
      break;
    case "mysql":
      warningLimit = maxConnections * 0.6;
      koLimit = maxConnections * 0.9;
      break;
  }

  if (warningLimit && koLimit) {
    if (consumedValue > koLimit) {
      return "ko";
    }
    if (consumedValue > warningLimit) {
      return "warning";
    }
    return "ok";
  } else {
    return null;
  }
}
