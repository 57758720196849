<template>
  <ConfirmModal
    :immediate="false"
    kind="danger"
    :promiseInfo="context.info"
    :strongConfirmation="context.mysql_database_name"
    :title="$t('title')"
    panelClasses="sm:w-full sm:max-w-xl"
    @close="(e) => $emit('close', e)"
    @confirm="(e) => $emit('confirm', e)"
  >
    <p class="mt-2">
      {{ $t("disclaimer") }}
    </p>

    <SCAlert v-if="context.info?.isError" class="my-4" kind="error">
      <p class="px-4 font-bold">{{ context.error }}</p>
    </SCAlert>
  </ConfirmModal>
</template>

<script>
import { defineComponent } from "vue";

import SCAlert from "@/components/molecules/alerts/SCAlert.vue";
import ConfirmModal from "@/components/molecules/modals/ConfirmModal.vue";

export default defineComponent({
  name: "DeleteDatabaseModal",
  components: { ConfirmModal, SCAlert },
  props: {
    context: { type: Object, required: true },
  },
  emits: ["close", "confirm"],
  computed: {
    title() {
      return this.$t("title", { name: this.context.mysql_database_name });
    },
  },
});
</script>

<i18n>
en:
  title: "Drop {name}"
  disclaimer: "Dropping a database is a permanent action that cannot be undone."
fr:
  title: "Supprimer {name}"
  disclaimer: "Supprimer une base de données est une action permanente qui ne peut pas être annulée."
</i18n>
